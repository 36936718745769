export function resolveApiData(data, property) {
    if (data && data[property] && data[property] !== null) {
        return data[property];
    }

    return undefined;
}

export function resolveUspsWithIcon(sections) {
    if (!Array.isArray(sections) || sections.length === 0) {
        return undefined;
    }

    return sections.map(section => ({
        title: section?.title,
        icon: {
            url: section.images?.[0]?.url ?? "/images/icon-package.svg",
            alt: section.images?.[0]?.alt ?? section.title
        }
    }));
}

export function resolveImages(images, mobile, desktop) {
    if (images && Array.isArray(images) && images.length > 0) {
        if (images.length === 1) {
            return { mobile: images[0], desktop: images[0] };
        }

        if (images.length > 1) {
            return { mobile: images[0], desktop: images[1] };
        }
    }

    return {
        mobile,
        desktop
    };
}
