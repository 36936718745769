import PropTypes from "prop-types";
import Link from "next/link";
import Image from "next/image";

import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";
import { resolveImages } from "helpers/page";

import Button from "components/ui/button/Button";

import {
    Container,
    Block,
    ImageWrapper,
    Content,
    Title,
    Description
} from "./DoubleCard.styled";

function DoubleCard({
    title,
    description,
    link,
    images,
    fullWidth,
    className,
    ...rest
}) {
    const { mobileView } = useMediaQueryContext();
    const titleElement = fullWidth ? "h2" : "h3";
    const defaultImage = {
        url: "/images/img-doubler-cards.png",
        alt: "Verdubbel de waarde van je kaart"
    };

    const { mobile, desktop } = resolveImages(
        images,
        defaultImage,
        defaultImage
    );

    const content = (
        <Content fullWidth={fullWidth}>
            <Title as={titleElement}>{title}</Title>
            <Description fullWidth={fullWidth}>{description}</Description>
            <Link href={link.url} passHref prefetch={false}>
                <Button variant="primary" element="a">
                    {link.label}
                </Button>
            </Link>
        </Content>
    );

    if (fullWidth) {
        return (
            <Container className={className} forwardedAs="section" {...rest}>
                <ImageWrapper fullWidth>
                    <Image
                        src={mobileView ? mobile.url : desktop.url}
                        alt={mobileView ? mobile.alt : desktop.alt}
                        width={509}
                        height={365}
                        quality={75}
                    />
                </ImageWrapper>
                {content}
            </Container>
        );
    }

    return (
        <Block className={className} {...rest}>
            <ImageWrapper fullWidth={fullWidth}>
                <Image
                    src={mobile.url}
                    alt={mobile.alt}
                    width={372}
                    height={250}
                    quality={75}
                />
            </ImageWrapper>
            {content}
        </Block>
    );
}

DoubleCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.shape({
        url: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    }),
    images: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired
        })
    ),
    fullWidth: PropTypes.bool,
    className: PropTypes.string
};

DoubleCard.defaultProps = {
    title: "Verdubbel de waarde van je cadeaukaart",
    description:
        "Maak kans op een verdubbeling van het bedrag op je cadeaukaart. Vul meteen je kaartnummer in en doe mee!",
    link: {
        url: "/verdubbelaar/",
        label: "ik doe mee!"
    },
    images: [
        {
            url: "/images/img-doubler-cards-large.jpg",
            alt: "Verdubbel de waarde van je cadeaukaart"
        }
    ],
    fullWidth: false,
    className: ""
};

export default DoubleCard;
