import styled, { css } from "styled-components";

import media from "constants/media";
import { columns } from "theme/mixins";

import BaseContainer from "components/ui/container/Container";
import ContentRenderer from "components/ui/contentRenderer/ContentRenderer";

export const Container = styled(BaseContainer)`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;

    ${media.mdUp} {
        flex-direction: row;
        gap: 0 20px;
        margin-bottom: 75px;
    }

    ${media.xlUp} {
        gap: 0;
        margin-bottom: 70px;
    }
`;

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
`;

export const ImageWrapper = styled.div`
    position: relative;

    flex: 0 0 auto;
    width: 100%;
    max-width: 300px;

    ${media.mdUp} {
        text-align: center;
    }

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            margin: 0 auto 10px;

            ${media.mdUp} {
                flex: 1 1 auto;
                max-width: calc(50% - 10px);
                margin: 0;
            }
            ${media.xlUp} {
                max-width: ${columns(7)};
            }
        `}

    ${({ fullWidth }) =>
        !fullWidth &&
        css`
            margin: 0 auto;

            ${media.mdUp} {
                max-width: 275px;
            }
            ${media.xlUp} {
                max-width: 500px;
            }
        `}
`;

export const Title = styled.h3`
    margin: 0 0 10px;

    font-size: var(--font-size-h4);
    line-height: var(--line-height-h4);

    ${({ as }) =>
        as === "h2"
            ? css`
                  ${media.lgUp} {
                      margin: 0 0 10px;

                      font-size: var(--font-size-h2);
                      line-height: var(--line-height-h2);
                  }
              `
            : css`
                  ${media.mdUp} {
                      margin: 5px 0 15px;
                  }
              `};
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${media.mdUp} {
        text-align: center;
    }

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            ${media.mdUp} {
                align-items: flex-start;
                text-align: left;
            }

            ${media.xlUp} {
                max-width: ${columns(4)};
            }
        `}
`;

export const Description = styled(ContentRenderer)`
    margin: 0 0 25px;

    color: var(--color-tertiary);

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            ${media.lgUp} {
                margin: 0 0 40px;
            }
        `}

    ${({ fullWidth }) =>
        !fullWidth &&
        css`
            ${media.mdUp} {
                max-width: 80%;
            }
        `}
`;
