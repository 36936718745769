import PropTypes from "prop-types";

function IconChevronLeft({ fill, size, ...rest }) {
    return (
        <svg
            viewBox="0 0 64 64"
            width={size}
            height={size}
            fill={fill}
            clipRule="evenodd"
            fillRule="evenodd"
            {...rest}
        >
            <path d="m37.4 13-18.9 19.1 18.9 18.9h8.1l-18.9-18.9 18.9-19.1z" />
        </svg>
    );
}

IconChevronLeft.propTypes = {
    fill: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.shape({ verticalAlign: PropTypes.string })
};

IconChevronLeft.defaultProps = {
    fill: "var(--color-black)",
    size: 48,
    style: { verticalAlign: "middle" }
};

export default IconChevronLeft;
